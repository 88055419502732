import 'slick-carousel'

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(){
    $('.menu-main-menu-container').toggleClass('active')
    $(this).toggleClass('active')
  })

  // artists slider
  $('.slider-artists').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    centerMode: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: '150px',
        }
      }
    ]
  });
  
  const pass = wp.assetsPass
  let theTarget

  $('.asset-trigger').click(function(e){
    e.preventDefault()
    theTarget = e.currentTarget.nextElementSibling
    $('.popup.password').addClass('active')
  })

  $('#assets-form').submit(function(e){
    e.preventDefault()
    const value = $('#pass').val()
    if (value == pass){
      $('.popup.password').removeClass('active')
      $('.asset-trigger').hide()
      $('.pass-alert').hide()
      $(theTarget).show()
      $('#pass').val('')
    } else{
      $('.pass-alert').show()
    }
  })
  
  $('.popup .close').click(function(){
    $('.popup.password').removeClass('active')
  })
  
  $('.asset-content .close').click(function(){
    $('.asset-content').hide()
    $('.asset-trigger').show()
  })

});